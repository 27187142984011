// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-bluefin-gatsby-plugin-fisherman-checkout-templates-checkout-checkout-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-checkout/templates/Checkout/Checkout.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-checkout-templates-checkout-checkout-jsx" */),
  "component---node-modules-bluefin-gatsby-plugin-fisherman-checkout-templates-order-confirmation-order-confirmation-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-checkout/templates/OrderConfirmation/OrderConfirmation.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-checkout-templates-order-confirmation-order-confirmation-jsx" */),
  "component---src-pages-blog-and-feedback-jsx": () => import("./../../../src/pages/blog-and-feedback.jsx" /* webpackChunkName: "component---src-pages-blog-and-feedback-jsx" */),
  "component---src-pages-catering-jsx": () => import("./../../../src/pages/catering.jsx" /* webpackChunkName: "component---src-pages-catering-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-locations-jsx": () => import("./../../../src/pages/locations.jsx" /* webpackChunkName: "component---src-pages-locations-jsx" */),
  "component---src-pages-menu-jsx": () => import("./../../../src/pages/menu.jsx" /* webpackChunkName: "component---src-pages-menu-jsx" */),
  "component---src-pages-order-online-jsx": () => import("./../../../src/pages/order-online.jsx" /* webpackChunkName: "component---src-pages-order-online-jsx" */),
  "component---src-pages-share-with-us-jsx": () => import("./../../../src/pages/share-with-us.jsx" /* webpackChunkName: "component---src-pages-share-with-us-jsx" */),
  "component---src-pages-shop-jsx": () => import("./../../../src/pages/shop.jsx" /* webpackChunkName: "component---src-pages-shop-jsx" */)
}

